.parent-testimonies{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-testimonies{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-control-next, .carousel-control-prev{
    width: 0%;
}
/* .row{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} */

.quote{
    font-size: 105px;
}
