.message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--pry-blue-color); */
  margin-top: 10vh;
  /* margin-bottom: 1.5rem;   */
}
.pas {
  width: 50%;
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-start; */
  flex-direction: column;
  padding: 2rem 0;
}
.message h1 {
  text-align: center;
  font-weight: var(--font-large);
  line-height: 1.3;
  padding-bottom: 10px;
  color: var(--pry-blue-color);
}
form {
  padding: 20px 0;
  width: 100%;
  /* display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column; */
}
.name {
  width: 100%;
  color: var(--main-color);
}
#na {
  /* padding: 3px 0; */
  width: 100%;
  border: 2px solid var(--pry-blue-color);
  background-color: transparent;
  margin-bottom: 10px;
  outline: none;
  resize: none;
  color: var(--pry-blue-color);
  padding: 20px;
}
#na::placeholder {
  color: var(--pry-blue-color);
}
.write {
  width: 100%;
}
#writ {
  background-color: transparent;
  width: 100%;
  border: 2px solid var(--pry-blue-color);
  border-radius: 0;
  outline: none;
  resize: none;
  color: var(--pry-blue-color);
  padding: 20px;
}
#writ::placeholder {
  color: var(--pry-blue-color);
}
.mid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactus {
  background-color: var(--pry-blue-color);
  text-decoration: none;
  border: none;
  color: var(--pry-white-color);
  padding: 11px 47px;
  font-size: 18px;
  font-weight: var(--font-large);
  transition: 0.5s linear;
}
.contactus:hover {
  outline: 2px solid var(--pry-blue-color);
  outline-offset: 5px;
  outline-style: groove;
  background-color: var(--pry-blue-color);
  color: var(--pry-white-color);
}
@media (max-width: 992px) {
  .expect {
    display: none;
  }
  .do {
    width: 100%;
  }
  .mob {
    display: block;
    width: 100%;
  }
  .pac {
    flex-direction: column;
    width: 100%;
  }
  .back {
    margin-bottom: 30px;
    padding: 30px 0 30px 25px;
  }
  .pas {
    width: 90%;
  }
  form {
    width: 100%;
  }
  .contactus {
    width: 100%;
  }
}
