.app__navbar-logo img {
  /* width: 20%; */
  height: 100%;
}
.nav-btn {
  cursor: pointer;
  font-size: 1.2rem;
  /* animation */
}

/* @keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
} */

.navbar {
  box-shadow: none !important;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 90% !important;
  height: 100% !important;
}
.navbar-brand {
  margin: 0 !important;
  width: 10% !important;
  height: 100% !important;
}
/* .active {
  font-size: 20px;
  font-weight: 9000;
  line-height: 24px;
  letter-spacing: 0em;
} */

.navbar .container-fluid {
  max-width: 100%;
}

.nav-flex {
  max-width: 400px;
}

/* .nav-flex img {
  margin: 0px 20px;
} */

.nav-flex h1 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  color: white !important;
  margin: 0;
}

.nav-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  /* color: var(--sec-black-color) !important; */
  margin-right: 15px;
  transition: 0.5s linear;
}
.nav-link::after {
  content: '';
  width: 0%;
  height: 2px;
  background-color: var(--pry-blue-color) !important;
  display: block;
  margin: auto;
  transition: 0.5s linear;
}
.nav-link:hover::after {
  width: 100%;
}
.nav-link:hover {
  color: var(--pry-blue-color) !important;
}

.navbar-collapse {
  flex-grow: 0;
}

.malt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
/* The sidebar menu */
.sidebar {
  text-align: start;
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 2000;
  /* Stay on top */
  top: 0;
  right: 0;
  background: var(--pry-white-color);
  /* Black*/
  /* Disable horizontal scroll */
  padding-top: 60px !important;
  margin-top: 10vh !important;
  /* Place content 60px from the top */
  transition: 1s ease;
  /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  font-size: 16px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: start;
  padding: 8px 8px 8px 45px;
  color: var(--pry-black-color);
  text-decoration: none;
}
.btc {
  /* background-color: var(--pry-blue-color) !important; */
  font-weight: 600;
  outline: none;
  transition: 0.5s linear;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: var(--pry-blue-color);
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 28px;
  color: var(--pry-green-color);
}

.openbtn:hover {
  color: var(--pry-green-color);
}

.body-close {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  bottom: 0%;
  top: 0%;
}

.navbar-light .navbar-toggler {
  color: var(--pry-green-color) !important;
  border: 0 !important;
}
