.parent-discuss{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-discuss{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 6rem 0 4rem 0;
}
.sub-discuss p{
    max-width: 60%;
    padding:  1.2rem 0;
}
@media screen and (max-width:992px) {
    .sub-discuss p{
        max-width: 100%;
    }   
}