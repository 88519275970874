/* .cert{
    height: 60vh;
} */
.cert img{
    height: 100%;
    object-fit: cover;
}
.first p{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.dots {
    position: relative;
  width: 80%;
  object-fit: cover;
  border-radius: 10px 0rem 10px 10px;
}
.cef {
  width: 90% !important;
}
.dot::before{
  content: '';
  background-color: #9f3b3b;
  height: 100%;
  width: 80%;
  position: absolute;
    z-index: -1;
  box-sizing: border-box;
  bottom: -0.0035rem;
  left: -0.65rem;
    border-radius: 10px 0rem 10px 10px;
}
.dot {
  position: relative;
}