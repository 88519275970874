.parent-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
}
.sub-more {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
}
.cards {
  background-color: var(--pry-white-color);
  border-bottom: 4px solid var(--pry-blue-color);
  transition: all 0.5s linear;
  width: 100%;
}
.card-icon img {
  padding: 50px;
}
.cards:hover {
  background-color: var(--pry-blue-color);
  color: var(--pry-white-color);
  border-bottom: 4px solid var(--pry-white-color);
  transform: scale(1.01);
}
.card-icon:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.more-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.cards p {
  /* text-align: center; */
  font-size: 14px;
  font-weight: 800;
}
.card-icon {
  width: 50px;
  margin-bottom: 2rem;
  height: 50px;
  background-color: rgba(51, 102, 176, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s linear;
}
