.parent-who {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sub-who {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  background-color: rgba(246, 184, 0, 0.02);
}

/* .who-img img {
  width: calc((100% / 3) - 2rem);
} */
  .who-img img{
    margin-bottom: 3rem;
  }
.who-img img:hover {
  margin-bottom: 2rem;
  transition: 0.5s linear;
  transform: scale(1.04);
}

