.citys {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 25.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/city.png);
  height: 18vh;
  width: 80%;
  border-radius: 8px;
  object-fit: cover;
  position: relative;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.polls {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 25.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/poll.png);
  /* border: 5px solid red; */
  height: 18vh;
  width: 80%;
  border-radius: 8px;
  object-fit: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.leaps h6 {
  color: var(--pry-white-color);
  width: 80%;
  font-size: 14px;
  font-weight: bold;
}
.houses {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 18.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/house.png);
  height: 18vh;
  width: 80%;
  border-radius: 8px;
  object-fit: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
