.par-header {
  width: 100%;
  min-height: 100vh;
  background: url(../../../images/header.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 11vh 0 10vh 0;
}
.sub-header {
  width: 90%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 50px;
  color: var(--pry-white-color);
}
.sub-header h1 {
  font-size: 5rem;
}

.sub-header h4 {
  font-size: 2rem;
}
.top-row{
  margin-top: 40px;
}
.headcircle {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
}
.sub2-header {
  height: 20%;
}
.sub-header .bth {
  background-color: #fff;
  color: #3366b0;
}
.par-header .down {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@media screen and (max-width: 768px) {
  .sub-header p {
    font-size: 12px;
  }
}
@media screen and (max-width: 420px) {
  .sub-header p {
    font-size: 10px;
  }
}
@media screen and (max-width: 360px) {
  .sub-header h1{
    font-size: 2.4rem;
  }
}@media screen and (max-width: 768px) {
  .sub-header h1 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 567px) {
  .sub-header h1 {
    font-size: 3rem;
  }
  .headcircle {
  width: 7px;
  height: 7px;
}
}
/* .par-header .down svg{
    width: 20px;
} */
