.parent-information {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}
.sub-information {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.boxs {
  width: 15px;
  height: 15px;
  background-color: var(--pry-blue-color);
}
.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.name p {
  padding: 0;
  margin: 0;
}
