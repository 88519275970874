@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5 !important;
  scroll-behavior: smooth;
  width: 100% !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tabs {
  width: 500px;
  height: 50px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #3366B0;
  margin-bottom: 20px;
}

.tabInner {
  width: 50%;
  height: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3366B0;
  cursor: pointer;
  justify-content: center;

}

.tabInner.middle {
  border-right: 1px solid #3366B0;
  border-left: 1px solid #3366B0;
}

.tabInner.red {
  background-color: #3366B0;
  color: white;
}

.tabInner.topradius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tabInner.bottomradius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tabInner p {
  margin: 0px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width:600px) {
  .tabs {
    width: 400px;
  }
}

@media screen and (max-width:500px) {
  .tabs {
    width: 320px;
  }

  .tabInner p {
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
  }
}