.not-found {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.not-found h1 {
  font-size: 12rem;
  color: var(--pry-blue-color);
}
.not-found h4 {
  color: var(--pry-blue-color);
}
@media screen and (max-width: 400px) {
  .not-found h1 {
    font-size: 8rem;
  }
}
