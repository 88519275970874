.parent-projectmore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.sub-projectmore {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.proj1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.proj2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.bot {
  width: 20px;
  height: 20px;
  background-color: var(--pry-blue-color);
  margin-right: 10px;
}

.proj1 h1 {
  color: rgba(12, 12, 12, 0.1);
  font-size: 16rem;
  width: 30%;
  padding-right: 3.5rem;
  z-index: -1;
}

.proj1 img {
  width: 70%;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .proj1 h1 {
    font-size: 12rem;
  }
}

@media screen and (max-width: 700px) {
  .proj1 h1 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 500px) {
  .proj1 h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 360px) {
  .proj1 h1 {
    font-size: 4rem;
  }

  .proj2 h4 {
    font-size: 1.2rem;
  }

  .proj3 p {
    font-size: 14px;
  }
}