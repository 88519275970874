.parent-board{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-board{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}