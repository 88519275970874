:root {
  --pry-white-color: #ffffff;
  --pry-blue-color: #3366b0;
  --pry-black-color: #000000;
  --sec-black-color: #141414;

  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --font-large: 600;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: var(--pry-white-color);
}
::-webkit-scrollbar-thumb {
  background: var(--pry-blue-color);
  height: 3rem !important;
  /* border-radius: var(--radius); */
}
.yellow {
  color: var(--pry-blue-color);
}

.nav-scrolled {
  background: #fff;
}
/* .btn{
  color: var(--pry-white-color) !important;
} */
@media screen and (max-width:992px) {
  p{
    text-align: justify !important;
  }
}