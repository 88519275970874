.parent-home {
  width: 100%;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 4rem 0;
}
.curve {
  z-index: -2;
  width: 70%;
  left: -10%;
  top: 10%;
  position: absolute;
}
.norm {
  position: relative;
  width: 100%;
  height: 80vh;
  object-fit: cover;
  border-radius: 10px 0rem 10px 10px;
}
.home-img::before {
  content: '';
  background-color: #DD2323;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  box-sizing: border-box;
  bottom: -0.0035rem;
  left: -0.65rem;
  border-radius: 10px 0rem 10px 10px;
}
.home-img {
  position: relative;
  width: 100%;
}
.home1 {
  width: calc((100% / 2) - 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home2 {
  width: calc((100% / 2) - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub_home1 {
  width: 100%;
}
.sub_home2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sub_home2 h6 {
  color: var(--pry-white-color);
  width: 80%;
  font-size: 14px;
  font-weight: bold;
}
.sub_home21 h4 {
  color: var(--pry-white-color);
  padding: 0 10px;
}
.circle1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--pry-blue-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* top: -1.5rem; */
  left: 0;
}
.sub_circle1 {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color: var(--pry-white-color);
}
.city {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 25.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/city.png);
  width: 30%;
  height: 25vh;
  object-fit: cover;
  position: relative;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: start;
  margin-right: 20px;
}
.poll {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 25.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/poll.png);
  /* border: 5px solid red; */
  width: 30%;
  height: 25vh;
  object-fit: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: start;
  margin-right: 20px;
}
.btcs{
  background-color: var(--pry-blue-color);
  box-shadow: none;
  color: #fff !important;
}
.house {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(0, 0, 0, 0.47053) 25.65%,
      rgba(0, 0, 0, 0.310681) 30.94%,
      rgba(0, 0, 0, 0) 77.6%
    ),
    url(../../../images/house.png);
  width: 30%;
  height: 25vh;
  object-fit: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: start;
}
.hide {
  display: none;
}

@media screen and (max-width: 992px) {
  .parent-home {
    display: none;
  }
  .hide {
    display: block;
  }
  .parent-home1 {
    width: 100%;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .city,
  .house,
  .poll {
    margin-bottom: 20px;
  }
}

/* the mobile */

.sub-home1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 2rem 0;
}
.sub_home21 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.side1 {
  width: calc(100% / 1);
}
.city,
.house,
.poll {
  width: 100%;
}
