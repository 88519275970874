.showproject {
    margin-top: 10vh;
    width: 90% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.showproject img {
    object-fit: cover;
}


.cite {
    border-radius: 100%;
    background-color: rgba(51, 102, 176, 0.2);
    padding: 12px;
}

.point {
    cursor: pointer;
}