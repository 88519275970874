.parent-service{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
}
.sub-service{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sub-service p{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
@media screen and (max-width:992px) {
    .direction{
        flex-direction: column-reverse !important;
    }
    .fup{
        width: 100%;
        margin-bottom: 30px !important;
    }
}