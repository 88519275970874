.parent-vision {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sub-vision {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
}
@media screen and (max-width: 992px) {
  .sub-vision {
    flex-direction: column;
  }
  .vision2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-bottom: 3rem;
  }
  .vision2 img {
    width: calc(100% / 3) !important;
    margin-bottom: 0px !important;
  }
  .vision1,
  .mission1 {
    margin-right: -30px;
  }
  .vision1 {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .core,
  .vision,
  .mission {
    padding: 1.2rem 0rem 1.2rem 1.5rem !important;
  }
}
.core,
.vision,
.mission {
  border-left: 4px solid var(--pry-blue-color);
  padding: 1.2rem 2rem;
  margin-bottom: 2rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.vision1 {
  width: calc(70% - 2rem);
}
.vision2 {
  width: calc(30% - 2rem);
}
.vision2 img {
  width: 70%;
}
.vision1,
.mission1 {
  margin-bottom: -40px;
}
